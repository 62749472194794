@import "node_modules/spectre.css/src/spectre";

@import url(https://fonts.googleapis.com/css?family=Poiret+One);

#logo-text {
  font-family: "Poiret One";
  margin-top: 5px;
  font-size: 36px;
  color: #333;
}

.vertical-center {
  min-height: 100%;
  min-height: 100vh;

  display: flex;
  align-items: center;
}